.chat-list .chat-text {
  border-radius: 6px !important;
  margin-bottom: 5px;
}

.chat-list .chat-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.chat-list li.even .chat-text {
  /* float: left; */
  clear: both;
  display: block;
  width: fit-content;
}

.chat-list li.odd .chat-text {
  float: right;
  clear: both;
}

.chat-list li .chat-text + .quick_reply {
  float: none;
  clear: both;
}

.chat-list li .chat-text + .quick_reply + img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 250px;
}

.chat-list li.even .chat-body .chat-text:first-child:before {
  position: absolute;
  top: 10px;
  right: 0;
  width: 0px;
  height: 0;
  content: initial;
  border: 5px solid transparent;
  border-right-color: #dfe9ef;
  border-left-color: transparent;
  z-index: 999;
  left: -10px;
}

.chat-list li.even .chat-body .button-include .chat-text:first-child:before {
  content: initial;
}

div.chat-box:not(.chat-box-optional)
  > .chat-list
  li.odd
  .chat-body
  .chat-text:first-child:before {
  position: absolute;
  top: 10px;
  right: -10px;
  width: 0px;
  height: 0;
  content: "";
  border: 5px solid transparent;
  border-right-color: #4f81bd;
  border-left-color: transparent;
  z-index: 999;
  left: initial;
  transform: rotate(180deg);
}

.quick_reply p {
  margin: 0px;
}

.quick_reply button {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 1.57142857;
  border-radius: 3px;
  -webkit-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -o-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #e4eaec;
  color: #3f4c53;
  background-color: transparent;
}

.quick_reply button {
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57142857;
  text-align: left;
  /* white-space: nowrap; */
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #e4eaec;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.quick_reply button:hover {
  background-color: #e4eaec;
}

.quick_reply button a {
  border-radius: 3px;
  -webkit-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -o-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  background-color: transparent;
  border: 0px solid;
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57142857;
  text-align: left;
  /* white-space: nowrap; */
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-decoration: none;
}

.quick_reply a {
  border-radius: 3px;
  -webkit-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -o-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #e4eaec;
  color: #ffffff;
  background-color: #4278b8;
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57142857;
  text-align: left;
  /* white-space: nowrap; */
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #e4eaec;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-decoration: none;
}

.quick_reply a:hover {
  background-color: #e4eaec;
  color: #3f4c53;
}

.quick_reply p {
  text-align: initial !important;
}

.chat-list .chat-text b {
  color: #a3afb7;
  font-size: 10px;
  opacity: 1;
  font-weight: normal;
  margin-top: 11px;
  display: block;
}

.chat-list .even .chat-text b {
  text-align: right;
}
