*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.site-glue-form-section .border-end, .border-start, .border-top, .border-bottom{
    border-color:#e1e1e1!important;
}

.site-glue-form-section{
    background: #F7F7FC;
    /* padding: 50px 12px; */
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #11142D;
    min-height: 100vh;
}
.site-glue-form-section .wrapper{
    max-width: 592px;
    width: 100%;
    margin: 0 auto;
    padding: 33px 48px 60px;
    border-radius: 8px;
    background-color: #fff;
}
.site-glue-form-section h1{
    font-size: 31px;
    font-weight: 700;
    margin-bottom: 14px;
}
.site-glue-form-section h2{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 14px;
}
.site-glue-form-section h3{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}
.site-glue-form-section h4{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}
.site-glue-form-section h5, .site-glue-form-section h6{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
}
.site-glue-form-section p{
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #11142D;
}
.site-glue-form-section .tab-small-text{
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    letter-spacing: 0.005em;
    color: #11142D;
}
.site-glue-form-section .small-text{
    font-size: 12px;
}
.site-glue-form-section a{
    color: #4F81BD;
    text-decoration: none;
    font-weight: 700;
}
.site-glue-form-section a.help-text-link{
    color: #9BBB59;
    margin-right:32px;
    margin-left: 0px;
    padding-top: 10px;
}
.site-glue-form-section img{
    max-width: 50px;
    height:auto;
}
.site-glue-form-section .form-box input, .site-glue-form-section .form-box button{
    width: 100%;
}
.site-glue-form-section .form-box fieldset{
    margin-bottom: 24px;
    border: none;
}
.site-glue-form-section .form-box .sg-submit .actions{
    text-align: right;
}
.site-glue-form-section .form-box input.sg-form-field{
    border: 2px solid rgba(79, 129, 189, 0.1);
    border-radius: 8px;
    padding: 0px 16px;
    height: 44px;
    background-color: #FFFFFF;
    color: #9A9AB0;
    outline: none !important;
    box-shadow:none !important;
}
.site-glue-form-section .form-box input[type="file"] {
    display: none;
}
.site-glue-form-section .form-box .custom-file-upload {
    background-color: #4F81BD !important;
    border-radius: 8px;
    border: 2px solid transparent;
    color: #fff !important;
    font-size: 16px !important;
    height: 40px;
    max-width: 104px;
    transform: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}
.site-glue-form-section .form-box select{
    border: 2px solid rgba(79, 129, 189, 0.1);
    border-radius: 8px;
    padding: 0px 16px;
    height: 40px;
    background-color: #FFFFFF;
    color: #9A9AB0;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    width :100%;
}
.site-glue-form-section .form-box textarea.sg-form-field {
    border: 2px solid rgba(79, 129, 189, 0.1);
    border-radius: 8px;
    padding: 16px 16px;
    height: 112px;
    background-color: #FFFFFF;
    color: #9A9AB0;
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
  }
.site-glue-form-section .form-box .form-select.db-select{
    background-image: url("https://webchatbot.app/images/grey-drop-icon.svg");
    background-position-x: calc(100% - 16px);
    background-position-y: center;
    background-size: 18px auto;
    background-repeat: no-repeat;
    padding: 0px 16px;
    border: 2px solid rgba(79, 129, 189, 0.1);
    height: 40px;
    width: 100%
}
.site-glue-form-section .form-box input.sg-form-field:focus{
    outline: none !important;
    box-shadow:none !important;
}
.site-glue-form-section .form-box .label-text{
    padding: 0px 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
}
.site-glue-form-section .form-box .f-pass{
    margin-top: 16px;
    text-align:right;
}
.site-glue-form-section .form-box .sg-button{
    padding: 0px 16px;
    height: 33px;
    background-color: #4F81BD;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    border: 0;
    max-width: 98px;
    margin: 0 auto;
    width: 100%;
}
.site-glue-form-section .form-box .sg-button-secondary{
    border: 2px solid #9A9AB0;
    color: #92929D;
    background-color: #fff;
}
.site-glue-form-section .form-box .sg-input-password{
    background-image: url("https://webchatbot.app/images/password-bg.svg");
    background-position: 94% center;
    background-repeat: no-repeat;
    background-size: 24px;
}
.btn-primary {
    color: #fff;
    background-color: #4F81BD;
    border-color: #4F81BD;
  }
  
@media (max-width:991px) {
    .site-glue-form-section .form-box fieldset.form-columns-2 {
        margin-bottom: 0px;
    }
    .site-glue-form-section .form-box fieldset.form-columns-2> div {
        margin-bottom: 24px;
    }
    .site-glue-form-section .wrapper{
        padding: 15px 12px 40px;
    }
}
.site-glue-form-section .input-group .type-msg-box {
    max-width: calc(100% - 72px);
    width: 100%;
    display: flex;
    border-radius: 8px !important;
    padding: 0px 20px 0px 0px;
    }
    .site-glue-form-section .input-group .input-group-send-button{
        max-width: 72px;
    width: 100%;
    }
    .site-glue-form-section .input-group {
    justify-content: center;
    padding: 20px 0px;
    display: flex;
    }
    .site-glue-form-section .input-group .input-group-send-button .send_btn {
    max-width: 72px;
    width: 100%;
    padding: 15px;
    border-radius: 6px;
    }
    .site-glue-form-section .input-group .type-msg-box .write_msg {
    width: calc(100% - 0px);
    border: 0;
    height: 56px;
    background-color: #F6F6F7;
    outline: none;
    padding: 18px 20px;
    border-radius: 8px;
    }
    .human-hand-form-section h2{
        text-align: center;
    }
    .site-glue-form-section .image-box{
        max-width: 57px;
        width: 100%;
        padding-top: 20px;
        padding-right: 7px;
        float: left;
    }
    .site-glue-form-section .right-up-button-box{
        max-width: calc(100% - 57px);
        width: 100%;
        color: #92929D;
        padding-left: 7px;
        float: left;
    }
    .site-glue-form-section .right-up-button-box .bot-reply-box{
        background: #F7F7FC;
        border-radius: 0px 4px 4px 4px;
        padding: 8px;
        margin-top: 4px;
    }
    .site-glue-form-section .bottom-text button{
        max-width: 132px;
        width: 100%;
        border: 1px solid #4F81BD;
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 110%;
        text-align: center;
        letter-spacing: 0.008em;
        color: #4F81BD;
        background-color: transparent;
    }
    .site-glue-form-section .bottom-text{
        margin-bottom: 34px;
    }
    .site-glue-form-section .form-wrap{
        border: 2px solid rgba(79, 129, 189, 0.2);
        border-radius: 8px;
        padding: 30px;
        margin-bottom: 32px;
    }
    .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
      }
      
      .alert h4 {
        margin-top: 0;
        color: inherit;
      }
      
      .alert .alert-link {
        font-weight: bold;
      }
      
      .alert > p,
      .alert > ul {
        margin-bottom: 0;
      }
      
      .alert > p + p {
        margin-top: 5px;
      }
      
      .alert-dismissable,
      .alert-dismissible {
        padding-right: 35px;
      }
      
      .alert-dismissable .close,
      .alert-dismissible .close {
        position: relative;
        top: -2px;
        right: -21px;
        color: inherit;
      }
      
      .alert-success {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
      }
      
      .alert-success hr {
        border-top-color: #c9e2b3;
      }
      
      .alert-success .alert-link {
        color: #2b542c;
      }
      
      .alert-info {
        background-color: #d9edf7;
        border-color: #bce8f1;
        color: #31708f;
      }
      
      .alert-info hr {
        border-top-color: #a6e1ec;
      }
      
      .alert-info .alert-link {
        color: #245269;
      }
      
      .alert-warning {
        background-color: #fcf8e3;
        border-color: #faebcc;
        color: #8a6d3b;
      }
      
      .alert-warning hr {
        border-top-color: #f7e1b5;
      }
      
      .alert-warning .alert-link {
        color: #66512c;
      }
      
      .alert-danger {
        background-color: #f2dede;
        border-color: #ebccd1;
        color: #a94442;
      }
      
      .alert-danger hr {
        border-top-color: #e4b9c0;
      }
      
      .alert-danger .alert-link {
        color: #843534;
      }